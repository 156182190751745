























































import { Component, Vue, Watch } from "vue-property-decorator";
import ProductsComponent from "@/components/Products.vue";

@Component({
  components: {
    ProductsComponent,
  },
})
export default class Products extends Vue {
  public currentCategory: string = "";

  @Watch("categories")
  categoriesChanged(value: any, oldValue: any) {
    if (this.categories.length >= 1)
      this.currentCategory = this.categories[0].shortcut;
  }

  mounted() {
    this.categoriesChanged(null, null);
  }

  get categories() {
    return this.$store.state.categories;
  }

  get products() {
    return this.$store.state.products.filter(
      (p: any) => p.category == this.currentCategory
    );
  }

  changeCategory(category: string) {
    this.currentCategory = category;
  }
}
