


















































































































import { Component, Vue } from "vue-property-decorator";
import Social from "@/components/Social.vue";

@Component({
  components: {
    Social,
  },
})
export default class Career extends Vue {
  public name: string = "";
  public email: string = "";
  public telephone: string = "";
  public about: string = "";

  public error: string = "";
  public success: string = "";

  public sending = false;

  mounted() {
    this.$emit("navigation", [
      {
        name: "Hlavní stránka",
        link: "/",
      },
      {
        name: "Pozice",
        to: "#jobs",
      },
      {
        name: "Kontakt",
        to: "#contact",
      },
    ]);
  }

  submit() {
    if(this.sending)
      return;

    this.sending = true;
    this.error = "";
    this.success = "";
    for (let variable of [this.name, this.email, this.telephone, this.about])
      if (variable.trim().length === 0) {
        this.error = "Všechny pole musí být vyplněné.";
        return;
      }

    if (this.name.length > 128) {
      this.error = "Jméno: toto pole může mít maximální délku 128 znaků.";
      return;
    }

    if (
      !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/.test(
        this.email
      )
    ) {
      this.error = "E-mail: zadaný e-mail není platný.";
      return;
    }

    if (this.email.length > 128) {
      this.error = "E-mail: toto pole může mít maximální délku 128 znaků.";
      return;
    }

    if (!/^(\d{9}|\d{3}\s\d{3}\s\d{3})$/.test(this.telephone)) {
      this.error = "Telefon: musí být ve formátu ### ### ###.";
      return;
    }

    if (this.about.length > 2048) {
      this.error = "O Vás: toto pole může mít maximální délku 2048 znaků.";
      return;
    }

    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState != 4) return;

      this.sending = false;

      if (xmlHttp.status != 200) {
        this.error = "Při odesílání nastala chyba, zkuste to později.";
        return;
      }

      this.error = "";
      this.success = "Zpráva byla úspěšně odeslána! Brzy se s Vámi spojíme.";
      this.name = "";
      this.email = "";
      this.telephone = "";
      this.about = "";
    };
    xmlHttp.open("POST", this.$store.state.api + "form/contact", true);
    xmlHttp.setRequestHeader("Content-Type", "application/json");
    xmlHttp.send(
      JSON.stringify({
        name: this.name,
        email: this.email,
        telephone: this.telephone,
        about: this.about,
      })
    );
  }
}
