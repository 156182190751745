

















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Navigation extends Vue {
  public mobileNavigation = false;
  public navigationParts: any = undefined;
  public nextLocation: any = {};
  @Prop() public links!: any;

  
  @Watch('links')
  linksChanged(value: any, oldValue: any) {
    setTimeout(() => {
      this.navigationParts = this.$el.querySelectorAll(
        "header nav > a:not(.menu):not([href])[data-to]"
      );
  
      document.removeEventListener("scroll", this.scrollFunction);
      document.addEventListener("scroll", this.scrollFunction);
      
      document.querySelectorAll("[data-to]").forEach((a: any) => {
        a.removeEventListener("click", (e: any) => this.scroll(e, {to: ""}));
        a.addEventListener("click", (e: any) => this.scroll(e, {to: ""}));
      });
      this.scrollFunction();
    }, 500);
  }

  mounted() {
    this.locationsChanged(null, null);
  }
  
  @Watch('locations')
  locationsChanged(value: any, oldValue: any) {
    let nextLocation: any = {};
    const currentDate = new Date();
    const currentDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    );

    for (let location of this.$store.state.locations.filter(
      (location: any) => location.date.getTime() > currentDay
    )) {
      if (nextLocation.place == undefined) {
        nextLocation = location;
        continue;
      }

      if (nextLocation.date.getTime() < location.date.getTime()) continue;

      nextLocation = location;
    }

    this.nextLocation = nextLocation;
  }

  get locations() {
    return this.$store.state.locations;
  }

  scrollFunction() {
    const top = window.pageYOffset || document.documentElement.scrollTop;

    let current;
    for (let part of this.navigationParts) {
      const element = document.querySelector(part.getAttribute("data-to"));
      if(element == null)
        return;

      const fromTop = element.offsetTop;
      const fromTopTreshold = fromTop - window.innerHeight * 0.5;

      if (fromTopTreshold <= top) current = part;
      else break;
    }

    for (let part of this.navigationParts) {
      if (part == current) part.classList.add("active");
      else part.classList.remove("active");
    }
  }

  openNavigation() {
    this.mobileNavigation = true;
    document.body.classList.add("scrollless");
  }
  closeNavigation() {
    this.mobileNavigation = false;
    document.body.classList.remove("scrollless");
  }

  scroll(event: any, data: any) {
    this.closeNavigation();

    if(data.to == undefined) {
      this.$router.push(data.link);
      return;
    }

    const link = event.target.closest("a");
    const target = document.querySelector(link.getAttribute("data-to"));

    if(target == null)
      return;

    target.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
