
















































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class LocationCurrent extends Vue {
  public locations: any = {};
  public formatter = new Intl.DateTimeFormat("cs");
  public currentDay: Date = new Date();

  formatDays(days: number) {
    if (days < 0) return "";

    if (days == 1) return "zítra";
    if (days > 1 && days < 5) return days + " dny";

    return days + " dní";
  }
  mounted() {
    this.locationsChanged(null, null);
  }

  @Watch('locationsReal')
  locationsChanged(value: any, oldValue: any) {
    const currentDate = new Date();
    this.currentDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    );

    this.locations = this.$store.state.locations
      .filter((location: any) => location.date.getTime() > this.currentDay)
      .sort((a: any, b: any) => a.date.getTime() - b.date.getTime());
  }

  get locationsReal() {
    return this.$store.state.locations;
  }
}
