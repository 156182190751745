



















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class LocationCurrent extends Vue {
  public nextLocation: any = {};
  public formatter = new Intl.DateTimeFormat("cs");
  public currentDay: Date = new Date();

  formatDays(days: number) {
    if (days < 0) return "";

    if (days == 1) return "zítra";
    if (days > 1 && days < 5) return days + " dny";

    return days + " dní";
  }
  mounted() {
    this.locationsChanged(null, null);
  }

  @Watch("locations")
  locationsChanged(value: any, oldValue: any) {
    let nextLocation: any = {};
    const currentDate = new Date();
    this.currentDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    );

    for (let location of this.locations.filter(
      (location: any) => location.date.getTime() > this.currentDay
    )) {
      if (nextLocation.place == undefined) {
        nextLocation = location;
        continue;
      }

      if (nextLocation.date.getTime() < location.date.getTime()) continue;

      nextLocation = location;
    }

    this.nextLocation = nextLocation;
  }

  get locations() {
    return this.$store.state.locations;
  }
}
