












import { Component, Vue } from "vue-property-decorator";
@Component
export default class ThemeSwitcher extends Vue {
  public theme: string = "";

  mounted() {
    const theme = localStorage.getItem("theme");

    if (theme == undefined || theme == null) {
      localStorage.setItem("theme", "light");
    }

    this.setTheme();
  }

  setTheme() {
    this.theme = localStorage.getItem("theme") as string;
    
    document.body.classList.remove("dark");
    document.body.classList.remove("light");

    document.body.classList.add(this.theme);
  }

  toggleTheme() {
    localStorage.setItem("theme", this.theme == "light" ? "dark" : "light");
    this.setTheme();
  }
}
