





































import { Component, Vue, Watch } from "vue-property-decorator";
import ProductsComponent from "@/components/Products.vue";

@Component({
  components: {
    ProductsComponent,
  },
})
export default class ProductSelection extends Vue {
  public products: Array<any> = [];

  @Watch('productsReal')
  productsChanged(value: any, oldValue: any) {
    this.products = this.$store.state.products.map((product: any) => {
      return {
        ...product,
        amount: 0
      }
    });
  }

  mounted() {
    this.productsChanged(null, null);
  }

  get productsReal() {
    return this.$store.state.products;
  }

  change(product: any, direction: number) {
    if(product.amount + direction > 99 || product.amount + direction < 0)
        direction = 0;

    product.amount += direction;
    this.$emit("products", this.products);
  }
}
