import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.NODE_ENV === 'development' ? "http://localhost:20214/" : "https://api.mastnahuba.cz/",
    products: [],
    categories: [],
    locations: []
  },
  mutations: {
    categories(state, value) {
      state.categories = value;
    },
    products(state, value) {
      state.products = value;
    },
    locations(state, value) {
      state.locations = value;
    }
  },
  actions: {
  },
  modules: {
  },
})
