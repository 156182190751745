






















































































































import { Component, Vue } from "vue-property-decorator";
import Social from "@/components/Social.vue";
import ProductSelection from "@/components/ProductSelection.vue";

@Component({
  components: {
    Social,
    ProductSelection,
  },
})
export default class Order extends Vue {
  public name: string = "";
  public email: string = "";
  public telephone: string = "";
  public address: string = "";
  public date: string = "";
  public other: string = "";

  public error: string = "";
  public success: string = "";

  public sending = false;

  private products: Array<any> = new Array<any>();

  productsChanged(products: Array<any>) {
    this.products = products;
  }

  mounted() {
    this.$emit("navigation", [
      {
        name: "Hlavní stránka",
        link: "/",
      },
      {
        name: "Volba produktů",
        to: "#select",
      },
      {
        name: "Dokončení",
        to: "#order",
      },
    ]);
  }

  submit() {
    if(this.sending)
      return;

    this.sending = true;
    this.error = "";
    this.success = "";
    for (let variable of [
      this.name,
      this.email,
      this.telephone,
      this.address,
      this.date,
    ])
      if (variable.trim().length === 0) {
        this.error = "*: toto pole je povinné.";
        return;
      }

    if (this.name.length > 128) {
      this.error = "Jméno: toto pole může mít maximální délku 128 znaků.";
      return;
    }

    if (
      !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/.test(
        this.email
      )
    ) {
      this.error = "E-mail: zadaný e-mail není platný.";
      return;
    }

    if (this.email.length > 128) {
      this.error = "E-mail: toto pole může mít maximální délku 128 znaků.";
      return;
    }

    if (!/^(\d{9}|\d{3}\s\d{3}\s\d{3})$/.test(this.telephone)) {
      this.error = "Telefon: musí být ve formátu ### ### ###.";
      return;
    }

    if (this.address.length > 128) {
      this.error = "Adresa: toto pole může mít maximální délku 128 znaků.";
      return;
    }

    if (this.date.length > 128) {
      this.error =
        "Den doručení: toto pole může mít maximální délku 128 znaků.";
      return;
    }

    if (this.other.length > 2048) {
      this.error = "Požadavky: toto pole může mít maximální délku 2048 znaků.";
      return;
    }

    if (this.products.length == 0) {
      this.error = "Produkty: musíte vybrat alespoň jeden produkt.";
      return;
    }

    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState != 4) return;

      this.sending = false;

      if (xmlHttp.status != 200) {
        this.error = "Při odesílání nastala chyba, zkuste to později.";
        return;
      }

      this.error = '';
      this.success = 'Zpráva byla úspěšně odeslána! Brzy Vás budeme kontaktovat o dalším postupu.'

      this.name = '';
      this.email = '';
      this.telephone = '';
      this.date = '';
      this.address = '';
      this.other = '';
    };
    xmlHttp.open("POST", this.$store.state.api + "form/order", true);
    xmlHttp.setRequestHeader("Content-Type", "application/json");
    xmlHttp.send(
      JSON.stringify({
        name: this.name,
        email: this.email,
        telephone: this.telephone,
        date: this.date,
        address: this.address,
        other: this.other,
        products: this.products.filter((product) => product.amount > 0).map((product) => {
          return {
            id: product.id,
            amount: product.amount
          }
        })
      })
    );
  }
}
