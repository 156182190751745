


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class RouterObserver extends Vue {
  @Watch("$route")
  linksChanged(value: any, oldValue: any) {
    document.body.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
