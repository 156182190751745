








































import { Component, Vue, Prop } from "vue-property-decorator";
import Navigation from "@/components/Navigation.vue";
import ThemeSwitcher from "@/components/ThemeSwitcher.vue";
import RouteObserver from "@/components/RouteObserver.vue";

@Component({
  components: {
    Navigation,
    ThemeSwitcher,
    RouteObserver
  },
})
export default class App extends Vue {
  private year = new Date().getFullYear();
  public links: any = [];
  public cookies: boolean = false;

  handle(links: any) {
    this.links = links;
  }

  close() {
    localStorage.setItem("cookies", "true");
    this.cookies = true;
  }

  mounted() {
    const cookies = localStorage.getItem("cookies");

    if(cookies == undefined || cookies === 'false')
      this.cookies = false;
    else
      this.cookies = true;

    for (let type of ["categories", "products", "locations"]) {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState != 4) return;

        if (xmlHttp.status != 200) {
          return;
        }

        let data = JSON.parse(xmlHttp.responseText).data[type];

        if(type == "locations") {
          data = data.map((d: any) => {
            return {
              ...d,
              date:  new Date(d.date)
            }
          })
        }

        this.$store.commit(type, data);
      };
      xmlHttp.open("GET", this.$store.state.api + type, true);
      xmlHttp.setRequestHeader("Content-Type", "application/json");
      xmlHttp.send();
    }
  }
}
