


























































































































































































import { Component, Vue } from "vue-property-decorator";
import Products from "@/components/Products.vue";
import Social from "@/components/Social.vue";
import LocationCurrent from "@/components/LocationCurrent.vue";
import LocationFollowing from "@/components/LocationFollowing.vue";
import LocationCurrentShort from "@/components/LocationCurrentShort.vue";

@Component({
  components: {
    Products,
    LocationCurrent,
    LocationFollowing,
    Social,
    LocationCurrentShort,
  },
})
export default class Home extends Vue {
  mounted() {
    this.$emit("navigation", [
      {
        name: "Produkty",
        to: "#products",
      },
      {
        name: "O nás",
        to: "#about",
      },
      {
        name: "Lokace",
        to: "#location",
      },
      {
        name: "Kontakt",
        to: "#contact",
      },
      {
        name: "Dovážka",
        link: "/order",
      },
    ]);
  }

  get categories() {
    return this.$store.state.categories;
  }

  get products() {
    return this.$store.state.products;
  }
}
